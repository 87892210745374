.root {
  width: 768px;
  height: auto;
  background: var(--white);
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  box-sizing: border-box;
  padding: 50px 0;
}

.root.isTabled {
  width: 90%;
  box-sizing: border-box;
  padding: 40px 16px;
}

.root.isMobile {
  width: 90%;
  box-sizing: border-box;
  padding: 0 16px;
  height: 640px;
  padding: 20px 0;
  overflow-y: scroll;
}

.closeIconWrapper:hover {
  cursor: pointer;
}

.closeIcon {
  position: absolute;
  top: 48px;
  right: 36px;
}

.root.isMobile .closeIcon {
  top: 18px;
  right: 26px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.root.isMobile .content {
  overflow-y: scroll;
  height: 80%;
  justify-content: flex-start;
  padding: 0 20px;
}

@media (max-height: 800px) {
  .root {
    max-height: 600px;
    overflow-y: scroll;
    align-items: flex-start;
  }

  .root .callButton {
    margin-top: 20px;
    margin-bottom: 17px;
  }
}

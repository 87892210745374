.root {
  display: flex;
  min-height: 58px;
  width: 245px;
  align-items: center;
  justify-content: center;
  color: var(--black);
  border-radius: 10px;
  border: 1px solid #41c8f4;
  background: var(--white);
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  box-sizing: border-box;
}

.icon {
  margin-right: 14px;
}

.root:hover {
  background: var(--blue-light);
  color: var(--white);
  border: 1px solid var(--blue-light);
  cursor: pointer;
}

.root:hover svg {
  fill: none;
}

.root:hover svg path {
  stroke: var(--white);
}

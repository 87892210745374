.wrapper {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  justify-content: space-between;
  padding: 25px 32px 25px 0;
}

.root.small {
  padding: 25px 30px 22px 30px;
  flex-direction: column;
}

.root.isMobile {
  padding: 40px 24px 27px 18px;
}

.groupWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.group {
  display: flex;
  align-items: center;
}

.phone {
  margin-left: 100px;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid var(--white);
}

.phone:hover {
  border: 1px solid var(--blue-light);
  border-radius: 5px;
  cursor: pointer;
}

.phoneIcon {
  margin-right: 10px;
}

.phoneNumber {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: var(--blue);
}

.phoneLabel {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  color: #828282;
}

.logo {
  width: 196px;
  height: 56px;
}

.logo:hover {
  cursor: pointer;
}

.iconMenuWrapper {
  margin-right: 33px;
}

.root.isMobile .iconMenuWrapper {
  margin-right: 20px;
}

.root {
  border-radius: 5px;
  border: 1px solid #ececec;
}

.root :global(.MuiOutlinedInput-notchedOutline) {
  border: none;
}

.root :global(.MuiInputBase-input) {
  color: #828282;
  font-family: Stolzl;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

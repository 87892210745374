.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 386px;
  margin-top: 42px;
}

.root.isTabled {
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
}

.root.isMobile {
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
}

.heplerText {
  margin: 14px 0 41px 0;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: var(--gray);
}

.root {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 26px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.root::after {
  content: '';
  display: block;
  width: 206px;
  height: 1px;
  background: rgba(0, 0, 0, 0.06);
  position: absolute;
  bottom: 0;
}

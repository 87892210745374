.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 67px 10px 67px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  text-decoration: none;
  justify-content: space-between;
}

.counter {
  color: var(--blue-light);
}

:global(.active).link {
  background: linear-gradient(
    270deg,
    rgba(18, 81, 160, 0.06) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.root {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}

.root .mainText {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.root .optionalText {
  color: var(--gray);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin-left: 4px;
  margin-top: 2px;
}

.root .small {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.root {
  display: flex;
  border-radius: 8px;
  border: 1px solid #ececec;
  width: 100%;
  padding: 4px;
  gap: 8px;
  box-sizing: border-box;
}

.tab {
  flex: 1 1 0px;
  border-radius: 10px;
  border: 1px solid var(--white);
  background: var(--white);
  color: #000000;
  text-align: center;
  font-family: Stolzl;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.tab:hover {
  border-radius: 10px;
  border: 1px solid #f6f6f6;
  background: #f6f6f6;
  cursor: pointer;
}

.tab.isSelected {
  border: 1px solid var(--blue-light);
  background: var(--blue-light);
  color: #fff;
}

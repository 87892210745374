.root {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
}

.success {
  color: var(--green);
}

.error {
  color: var(--secondary);
}

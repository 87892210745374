.root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px 0px 0px 32px;
  overflow: hidden;
  height: 100vh;
}

.small {
  padding: 0;
}

header {
  display: flex;
}

main {
  display: flex;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}

.content {
  width: 100%;
  background: #f9fbfe;
  padding: 80px 30px 30px 30px;
  box-sizing: border-box;
  overflow-y: auto;
}

.content.isMobile {
  padding: 50px 15px 172px 15px;
}

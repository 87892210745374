.root {
  padding: 31px 0px 28px 23px;
  background: var(--white);
  display: flex;
  align-items: center;
  position: relative;
  width: 462px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
}

.root.isMobile {
  width: 100%;
  padding: 20px 0px 20px 10px;
}

.icon {
  margin-right: 23px;
}

.messageBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message {
  margin-bottom: 8px;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  width: 305px;
}

.root.isMobile .message {
  width: 290px;
}

.messageAction {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #41c8f4;
}

.date {
  position: absolute;
  bottom: 13px;
  right: 20px;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary);
}

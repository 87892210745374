.h1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  color: var(--black);
  margin-bottom: 32px;
}

.h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  color: var(--black);
}

.h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: var(--black);
}

.blueColor {
  color: var(--blue-light);
}

.wrapper {
  display: flex;
  align-items: center;
}

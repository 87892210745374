.root {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: initial;
  display: inline-flex;
  width: fit-content;
}

.root:hover {
  cursor: pointer;
}

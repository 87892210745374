.root {
  background: var(--white);
  box-shadow: -34px 71px 32px rgba(147, 163, 169, 0.01),
    -19px 40px 27px rgba(147, 163, 169, 0.05),
    -9px 18px 20px rgba(147, 163, 169, 0.09),
    -2px 4px 11px rgba(147, 163, 169, 0.1), 0px 0px 0px rgba(147, 163, 169, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showMore {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #41c8f4;
  margin: 27px 0 23px 0;
}

.root.isEmpty {
  width: 400px;
  padding-top: 30px;
  align-items: center;
}

.root.isEmpty.isMobile {
  width: 280px;
  padding-top: 30px;
}

.root.isEmpty.isMobile .notNotifications {
  font-size: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
}
